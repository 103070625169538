<template>
    <b-modal
      v-model="data.showModal"
      size="lg"
      button-size="sm"
      modal-class="template-modal"
      title-class="w-full"
      footer-class="w-full"
      no-fade
      @show="onShow"
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
    >
      <!-- Modal header -->
      <div slot="modal-title">
        <div class="flex w-full justify-between">
          Edit Partner
        </div>
      </div>

      <!-- Modal content -->
      <div class="flex flex-col gap-2">
        <div v-if="partner === null && isLoading">
          Loading..
        </div>

        <div
          v-if="partner"
          class="flex justify-between gap-2"
        >
          <div class="w-full">
            <table
              class="table table-google"
            >
              <tbody>
                <tr>
                  <td class="text-nowrap">Name</td>
                  <td>
                    <b-form-input
                      v-model="partner.partner_name"
                      autocomplete="off"
                      size="sm"
                      trim
                      autofocus
                    ></b-form-input>
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">Subname</td>
                  <td>
                    <b-form-input
                      v-model="partner.partner_subname"
                      autocomplete="off"
                      size="sm"
                      trim
                    ></b-form-input>
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">Mail</td>
                  <td>
                    <b-form-input
                      v-model="partner.partner_email"
                      autocomplete="off"
                      size="sm"
                      trim
                    ></b-form-input>
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">Phone</td>
                  <td>
                    <b-form-input
                      v-model="partner.partner_phone"
                      autocomplete="off"
                      size="sm"
                      trim
                    ></b-form-input>
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">IČO</td>
                  <td>
                    <b-form-input
                      v-model="partner.partner_ico"
                      autocomplete="off"
                      size="sm"
                      trim
                    ></b-form-input>
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">DIČ</td>
                  <td>
                    <b-form-input
                      v-model="partner.partner_dic"
                      autocomplete="off"
                      size="sm"
                      trim
                    ></b-form-input>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
          <div class="w-full">
            <table
              class="table table-google"
            >
              <tbody>
                <tr>
                  <td class="text-nowrap">Street</td>
                  <td>
                    <b-form-input
                      v-model="partner.address_street"
                      autocomplete="off"
                      size="sm"
                      trim
                    ></b-form-input>
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">City</td>
                  <td>
                    <b-form-input
                      v-model="partner.address_city"
                      autocomplete="off"
                      size="sm"
                      trim
                    ></b-form-input>
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">ZIP</td>
                  <td>
                    <b-form-input
                      v-model="partner.address_zip"
                      autocomplete="off"
                      size="sm"
                      trim
                    ></b-form-input>
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">Country</td>
                  <td>
                    <v-select
                      v-model="partner.country_id"
                      :options="data.options.country"
                      size="sm"
                      label="text"
                      :reduce="option => option.value"
                    />
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>

      <!-- Modal footer -->
      <div slot="modal-footer" class="w-full d-flex gap-4 justify-content-between items-center">
        <div class="flex w-full justify-content-between">
          <div>
            <b-btn
              size="sm"
              variant="primary"
              :disabled="isLoading || !canSave"
              @click="onSave"
            >
              Save
            </b-btn>
          </div>
          <div class="flex gap-2">
            <b-btn
              size="sm"
              variant="danger"
              :disabled="isLoading"
              @click="deletePartner()"
            >
              Delete
            </b-btn>

            <b-btn
              size="sm"
              variant="white"
              :disabled="isLoading"
              @click="onClose"
            >
              Close
            </b-btn>
          </div>
        </div>
      </div>
    </b-modal>
  </template>

<script>
export default {
  name: 'PartnerModal',
  components: {},
  props: {
    data: Object,
    countries: Array,
    currencies: Array,
  },
  computed: {
    id() {
      return this.data?.record?.id;
    },
    isLoading() {
      return this.loadingCount > 0;
    },
    canSave() {
      if (!this.partner) {
        return false;
      }
      if (this.partner.partner_name.length === 0) {
        return false;
      }
      return true;
    },
  },
  data() {
    return {
      loadingCount: 0,
      filters: {},
      options: {},
      partner: null,
    };
  },
  methods: {
    fetchPartner() {
      this.loadingCount++;
      this.$http
        .get(`/partner/${this.id}`)
        .then((res) => {
          this.partner = res.body.record;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch partner: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    savePartner() {
      this.loadingCount++;
      this.$http
        .put(`/partner/${this.id}`)
        .send({ changes: this.partner })
        .then(() => {
          this.$emit('updated', this.id);
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(`Failed to update partner: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    deletePartner() {
      if (!confirm('Do you really wish to delete this partner?')) {
        return;
      }
      this.loadingCount++;
      this.$http
        .delete(`/partner/${this.id}`)
        .then(() => {
          this.$emit('deleted', this.id);
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete partner: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchAll() {
      this.fetchPartner();
    },
    onSave() {
      this.savePartner();
    },
    onShow() {
      this.fetchAll();
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.table-google tbody td {
  padding: 0 4px 8px;
}
</style>
